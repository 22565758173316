<template>
    <!-- Page content -->
    <main class="grow">

        <!-- Hero -->
        <section class="relative">

            <div class="max-w-6xl mx-auto px-4 sm:px-6">

                <!-- Hero content -->
                <div class="pt-32 pb-12 md:pt-40 md:pb-20 sm:text-center md:text-left">

                    <!-- Section header -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                        <div class="text-center md:text-left pb-12 md:pb-16">
                            <h1 class="text-4xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4">
                                Leading HSA tools & education.</h1>
                            <div class="max-w-xl">
                                <p class="text-xl text-gray-600 dark:text-gray-400 mb-8">We provide a powerful suite of branded HSA tools and educational materials to help your accountholders manage their HSA more effectively.
                                </p>
                                <div class="flex flex-col sm:flex-row justify-center md:justify-start">
                                    <NuxtLink class="w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4" href="/about/">
                                        <UButton size="xl" color="matisse" class="bg-matisse-600 hover:bg-matisse-500 py-3 px-6">Discover Why</UButton>
                                    </NuxtLink>
                                    <NuxtLink class="w-full sm:w-auto"  href="mailto:hsa@devenir.com?subject=Learn more about HSA Planner">
                                        <UButton size="xl" color="black" class="py-3 px-6">Contact Us</UButton>
                                    </NuxtLink>
                                </div>
                            </div>
                        </div>
                        <div class="hidden md:block max-w-lg">
                            <NuxtImg 
                                class="w-full mx-auto h-auto rounded-md" 
                                src="/imgs/learning.png" 
                                alt="Illustration of woman making HSA tool selections"
                                width="600"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <!-- Features -->
        <section class="relative">

            <!-- Section background (needs .relative class on parent and next sibling elements) -->
            <div class="absolute inset-0 bg-gray-100 dark:bg-gray-700 pointer-events-none mb-16" aria-hidden="true" />
            <div class="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2" />

            <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div class="pt-12 md:pt-20">

                    <!-- Section header -->
                    <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                        <h2 class="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl text-center mb-4">
                            HSA Guided Portfolio<sup class="trademark">&reg;</sup></h2>
                        <p class="text-xl text-gray-600 dark:text-gray-200">We each have unique circumstances and our HSA investments should reflect that.</p>
                        <p class="text-xl text-gray-600 dark:text-gray-200">“We Guide, You Choose”</p>
                    </div>

                    <div class="flex justify-center pb-12 md:pb-16 lg:w-4/5 max-w-3xl mx-auto">
                        <div class="relative w-full" style="padding-top: 56.25%;">
                            <iframe 
                            class="absolute top-0 left-0 w-full h-full"  
                            title="Introducing HSA Guided Portfolio"
                            src="https://player.vimeo.com/video/284836719" frameborder="0" allowfullscreen
                            kwframeid="1" sandbox="allow-scripts allow-presentation allow-same-origin" />
                        </div>
                    </div>


                    <!-- Section content -->
                    <div class="md:grid md:grid-cols-12 md:gap-6 items-center">

                        <!-- Content -->
                        <div class="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-6">
                            <div class="md:pr-4 lg:pr-12 xl:pr-16 mb-8">
                                <h3
                                    class="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl text-center lg:text-left mb-4">
                                    Popular HSA tools</h3>
                                <p class="text-xl text-gray-600 dark:text-gray-200">We're constantly looking at ways to enable consumers to get more out of their HSA.</p>
                            </div>

                            <!-- Tabs buttons -->
                            <div id="tab-container" class="mb-8 md:mb-0">
                                <button 
                                  v-for="item in tabItems" :key="item.id"
                                  :class="{ 'border-matisse-500 bg-blue-100 dark:bg-matisse-900': activeTab === item.id }"
                                  class="text-left flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-3 bg-white dark:bg-gray-800"
                                  @click="selectTab(item.id)"
                                  :aria-label="`Select ${item.title}`">
                                    <div>
                                        <div class="font-bold leading-snug tracking-tight mb-1">{{ item.title }}</div>
                                        <div class="text-gray-600 dark:text-gray-200">{{ item.content }}</div>
                                    </div>
                                    <div
                                        class="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3 dark:bg-black">
                                        <UIcon :name="item.iconName" class="w-4 h-4 text-matisse-500 dark:text-matisse-400" />
                                    </div>
                                </button>
                            </div>

                        </div>

                        <!-- Tabs items -->
                        <div class="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1">
                            <div class="flex justify-center items-center mx-auto">
                                <div 
                                v-for="item in tabItems" v-show="activeTab === item.id" :id="'item' + item.id"
                                :key="item.id" 
                                class="w-full flex justify-center items-center">
                                    <div class="relative inline-flex flex-col">
                                        <NuxtImg 
                                            loading="lazy" 
                                            class="mx-auto rounded transition-opacity duration-500 ease-in-out max-w-md" 
                                            :src="item.imageSrc" 
                                            :width="600" 
                                            quality="80"
                                            alt="Features bg" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>

        <!-- Cta -->
        <section class="py-12 md:py-20">
            <div class="max-w-6xl mx-auto px-4 sm:px-6">
                <div class="pb-12 md:pb-20">

                    <!-- CTA box -->
                    <div class="bg-matisse-600 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl">

                        <div class="flex flex-col lg:flex-row justify-between items-center">

                            <!-- CTA content -->
                            <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
                                <h3 class="text-3xl font-bold tracking-tight text-white sm:text-4xl mb-4">
                                    Ready to get started?</h3>
                                <p class="text-white text-lg">Let's figure out what makes sense for your HSA
                                    accountholders.</p>
                            </div>

                            <!-- CTA button -->
                            <div>
                                <NuxtLink href="mailto:hsa@devenir.com?subject=Learn more about HSA Planner">
                                    <UButton color="white" size="xl">Contact Us</UButton>
                                </NuxtLink>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>

    </main>
</template>
<script setup lang="ts">
definePageMeta({
    layout: 'marketing'
})

useSeoMeta({
    title: 'HSA Planner - Leading HSA Tools & Education',
    description: 'Discover powerful HSA tools and educational materials to help accountholders manage their HSA more effectively.',
    ogTitle: 'HSA Planner - Leading HSA Tools & Education',
    ogDescription: 'Powerful HSA tools and education for effective account management.',
})

// Active tab state
const activeTab = ref(1);

// Tab items
const tabItems = [
    {
        id: 1,
        title: "HSA Balance Projection",
        content: "Assists users in understanding how their HSA balance can grow over time in a visual manner.",
        iconName: "i-heroicons-chart-bar-20-solid",
        imageSrc: "/imgs/balanceprojectioncalc.jpg"
    },
    {
        id: 2,
        title: "HSA Eligible Expense Search",
        content: "Quickly enable consumers to determine if a medical expense is eligible to be paid for with their HSA.",
        iconName: "i-heroicons-magnifying-glass",
        imageSrc: "/imgs/hsaeligibleexpenses.jpg"
    },
    {
        id: 3,
        title: "HSA Asset Allocation Calculator",
        content: "Use this tool to create an asset allocation that may be appropriate for your HSA investment objectives based on the information you provide.",
        iconName: "i-heroicons-chart-pie-20-solid",
        imageSrc: "/imgs/assetallocationcalc.jpg"
    }
];

// Function to change active tab
const selectTab = (tabId: number) => {
    activeTab.value = tabId;
};
</script>